<template>
	<div class="page-container">
		<div class="page-head">
			<div class="title">{{$t('liveplatform.decoration.title')}}</div>
		</div>
		<div class="page-title">{{$t('liveplatform.decoration.pageTitle1')}}</div>
		<div class="page-list">
			<div class="item">
				<img src="@/assets/images/liveplatform/decoration-tps-204-360.png" alt="" class="item-pic">
				<div class="item-main">
					<div class="item-tit">{{$t('liveplatform.decoration.pageItem1-1')}}</div>
					<div class="item-text">{{$t('liveplatform.decoration.pageItem1-2')}}</div>
					<div class="item-foot">
						<el-button @click="$router.push({ name: 'LiveInformationcard' })" type="uudefault" size="small" round>{{$t('liveplatform.decoration.useBtn')}}</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: '1'
			}
		},
		methods: {
			handleClick(tab, event){
				console.log(tab, event);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-container {
		border-radius: 6px;
		padding: 24px;
		background-color: $--live-background-default;
		.page-head {
			height: 32px;
			margin-bottom: 10px;
			.title {
				float: left;
				height: 32px;
				line-height: 32px;
				font-size: 16px;
				color: #FFFFFF;
			}
			.action {
				float: right;
				.el-button--uudanger {
					color: #FFFFFF;
					border-color: $--live-button-primary-border;
					background-color: $--live-button-primary-background;
					&:focus,&:hover {
						border-color: $--live-button-primary-hover-border;
						background-color: $--live-button-primary-hover-background;
					}
				}
			}
		}
		::v-deep .el-tabs__header {
			.el-tabs__nav-wrap:after {
				background-color: transparent;
			}
			.el-tabs__nav {
				.el-tabs__item {
					color: $--live-text-color-2;
					height: 46px;
					line-height: 46px;
				}
				.el-tabs__item.is-active {
					color: $--live-tab-primary-active;
				}
				.el-tabs__active-bar {
					background-color: $--live-tab-primary-active;
				}
			}
		}
		.page-title {
			height: 46px;
			line-height: 46px;
			color: $--live-text-color-2;
			font-size: 14px;
		}
		.page-list {
			.item {
				width: 279px;
				height: 219px;
				border-radius: 6px;
				background-color: $--live-gray-white-6;
				padding: 20px 15px;
				box-sizing: border-box;
				margin-top: 12px;
				margin-right: 16px;
				.item-pic {
					float: left;
					width: 100px;
					height: 178px;
					object-fit: cover;
					border-radius: 6px;
				}
				.item-main {
					min-height: 178px;
					margin-left: 112px;
					.item-tit {
						font-size: 16px;
						color: #FFFFFF;
						height: 24px;
						line-height: 24px;
						margin-bottom: 2px;
					}
					.item-text {
						color: $--live-text-color-2;
						font-size: 14px;
						height: 66px;
						line-height: 22px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.item-foot {
						margin-top: 54px;
						text-align: right;
						.el-button--uudefault {
							color: #333333;
							border-color: #FFFFFF;
							background-color: #FFFFFF;
						}
						.el-button--uudefault:focus,.el-button--uudefault:hover {
							color: #333333;
							border-color: #FFFFFF;
							background-color: #FFFFFF;
						}
					}
				}
			}
		}
	}
</style>
